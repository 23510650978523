export const validateEmail = (email: string | null): boolean => {
  if (email !== null) {
    const regex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "i"
    );
    return regex.test(email);
  }
  return true;
};

export const validatePhoneNumber = (phone: string): boolean => {
  const regex = new RegExp("^([+]?1?([0-9]){10})$");
  return regex.test(phone);
};

export const validateFormattedPhoneNumber = (phone: string): boolean => {
  const regex = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
  return regex.test(phone);
};

export const validateName = (name: string): boolean => {
  const regex = new RegExp(
    "^[A-Za-z]+([ '-][A-za-z]+|([.][ ][A-Za-z]+([ '-][A-za-z]+)?))?([IVXLCDM]+)?$"
  );
  return regex.test(name);
};

const licensePlateRegex = /^[A-Za-z0-9]+$/i;
export const validateLicensePlate = (plate: string): boolean => {
  return licensePlateRegex.test(plate);
};

export const validateLicenseNumber = (license: string) => {
  const regex = new RegExp("^([A-Za-z0-9s-]|\\*\\*){7,17}$");
  return regex.test(license);
};

const vinRegex = /^[A-Za-z0-9]{11,17}$/i;
export const validateVinNumber = (vin: string): boolean => {
  return vinRegex.test(vin);
};
