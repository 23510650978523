import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  sendPasswordResetEmail,
  signInWithCustomToken,
  User,
} from "firebase/auth";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import { auth } from "shared/firebaseConfig/firebase";

import { signInWithEmailAndPassword } from "../services/user.service";

const AuthContext = createContext<{
  currentUser: User | null;
  login: (username: string, password: string) => Promise<void>;
  resetPassword: (username: string) => Promise<void>;
  logout: () => Promise<void>;
}>({
  currentUser: {} as User,
  login: async (_username: string, _password: string) => void 0,
  resetPassword: async (_username: string) => void 0,
  logout: async () => void 0,
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({
  children,
  disableAuth,
}: Readonly<{
  children: React.ReactNode;
  disableAuth: boolean;
}>) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const router = useRouter();
  const launchDarkly = useLDClient();
  const isIdentified = useRef(false);

  async function login(email: string, password: string) {
    const customToken = await signInWithEmailAndPassword(email, password);
    const credential = await signInWithCustomToken(auth, customToken);
    setCurrentUser(credential.user);
    await router.push("/");
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(auth, email);
  }

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (!disableAuth && !user) {
        router.push(`/auth/signin?redirectTo=${router.asPath}`);
      }

      if (user && launchDarkly && !isIdentified.current) {
        launchDarkly.identify({
          key: user.uid,
          email: user.email ?? undefined,
        });
        isIdentified.current = true;
      }
    });
  }, [disableAuth, router, launchDarkly, isIdentified]);

  const value = {
    currentUser,
    login,
    resetPassword,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {disableAuth || currentUser ? children : null}
    </AuthContext.Provider>
  );
}
