export enum ServiceCategory {
  AutobodyDamage = "AUTOBODY_DAMAGE",
  Glass = "GLASS",
  PaintlessDentRepair = "PAINTLESS_DENT_REPAIR",
  OilChange = "OIL_CHANGE",
  AcRecharge = "AC_RECHARGE",
  ScheduledMaintenance = "SCHEDULED_MAINTENANCE",
  WheelAlignment = "WHEEL_ALIGNMENT",
  Tires = "TIRES",
  Transmission = "TRANSMISSION",
  Suspension = "SUSPENSION",
  GeneralDiagnostics = "GENERAL_DIAGNOSTICS",
  Belts = "BELTS",
  Mufflers = "MUFFLERS",
  Battery = "BATTERY",
  EngineRepair = "ENGINE_REPAIR",
  BrakePads = "BRAKE_PADS",
  Other = "OTHER",
  ServiceManagerConsultation = "SERVICE_MANAGER_CONSULTATION",
  Towing = "TOWING",
}

export const serviceCategories: Record<ServiceCategory, string> = {
  [ServiceCategory.AutobodyDamage]: "Auto body",
  [ServiceCategory.Glass]: "Glass",
  [ServiceCategory.PaintlessDentRepair]: "Paintless Dent Repair",
  [ServiceCategory.OilChange]: "Oil Change",
  [ServiceCategory.AcRecharge]: "AC Recharge",
  [ServiceCategory.WheelAlignment]: "Wheel Alignment",
  [ServiceCategory.Tires]: "Tires",
  [ServiceCategory.Transmission]: "Transmission Services",
  [ServiceCategory.Suspension]: "Suspension",
  [ServiceCategory.GeneralDiagnostics]: "General Diagnostics",
  [ServiceCategory.Belts]: "Belts",
  [ServiceCategory.Mufflers]: "Mufflers & Catalytic Converters",
  [ServiceCategory.Battery]: "Battery Service",
  [ServiceCategory.EngineRepair]: "Engine Repair",
  [ServiceCategory.BrakePads]: "Brake Service",
  [ServiceCategory.Other]: "Other",
  [ServiceCategory.ServiceManagerConsultation]: "Service Manager Consultation",
  [ServiceCategory.Towing]: "Towing",
  [ServiceCategory.ScheduledMaintenance]: "Scheduled Maintenance",
};

export const serviceCategoriesLabels =
  Object.values(serviceCategories).sort(undefined);

export const serviceCategoriesKeys = Object.keys(
  serviceCategories
) as ServiceCategory[];

export function getServiceCategoryLabel(
  serviceCategory: string
): string | undefined {
  const uppercaseCategory = serviceCategory.toUpperCase();
  if (!isServiceCategory(uppercaseCategory)) return undefined;
  return serviceCategories[uppercaseCategory];
}

export const reverseCategories = Object.fromEntries(
  Object.entries(serviceCategories).map(([key, value]) => [value, key])
);

export function isServiceCategory(
  category: string
): category is ServiceCategory {
  return Object.keys(serviceCategories).includes(category);
}

export function getServiceCategories(
  services: { category?: string | null }[]
): ServiceCategory[] {
  return services
    .filter((s) => s.category && isServiceCategory(s.category))
    .map((s) => s.category as ServiceCategory);
}

export function checkIfServiceIsCollision(service: string): boolean {
  const collisionSet = new Set([
    ServiceCategory.AutobodyDamage,
    ServiceCategory.Glass,
    ServiceCategory.PaintlessDentRepair,
  ]);
  return isServiceCategory(service) && collisionSet.has(service);
}

export function checkIfRequestIsCollision(
  services: { category?: string | null }[]
): boolean {
  if (!services.length) return false;
  const serviceCategories = getServiceCategories(services);
  return serviceCategories.filter(checkIfServiceIsCollision).length > 0;
}
