import { useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { getDisplayName } from "next/dist/shared/lib/utils";
import { toast } from "react-hot-toast";

import { DisplayError } from "shared/components/DisplayError";
import PhoneNumberInput from "shared/components/input/PhoneNumberInput";
import {
  updateServiceAdvisor,
  updateUser,
} from "shared/generated/clients/user.client";
import { uploadFile } from "shared/lib/firebaseStorage";
import {
  validateEmail,
  validateFormattedPhoneNumber,
  validateName,
} from "shared/lib/stringValidation";
import { normalizePhoneNumber } from "shared/lib/utils";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  width: 400,
  borderRadius: 1,
  zIndex: 9999,
};

type Payload = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  avatarUrl: string | null;
  advisor?: {
    businessPhoneNumber: string;
    role: string;
  };
};

export default function EditUserModal({
  show,
  onClose,
  refetch,
  user,
}: Readonly<{
  show: boolean;
  onClose: any;
  refetch: any;
  user: any;
}>) {
  const [staffData, setStaffData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    avatarUrl: user.avatarUrl,
    extNumber: user?.advisor?.businessPhoneNumber,
    role: user?.advisor?.role,
  } as any);
  const [updatingUser, setUpdatingUser] = useState(false);
  const [validFirstName, setValidFirstName] = useState(true);
  const [validLastName, setValidLastName] = useState(true);
  const [validEmail, setValidEmail] = useState(validateEmail(user.email));
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [error, setError] = useState("");
  const [isLoadingAvatar, setIsLoadingAvatar] = useState(false);
  const avatarUploadRef = useRef<HTMLInputElement>(null);

  const handleClose = (e: any) => {
    e.preventDefault();
    onClose();
  };

  const clickUpdateUser = async () => {
    if (validFirstName && validLastName && validEmail && validPhoneNumber) {
      setUpdatingUser(true);

      const payload: Omit<Payload, "advisor"> = {
        firstName: staffData.firstName,
        lastName: staffData.lastName,
        email: staffData.email,
        avatarUrl: staffData.avatarUrl,
      };

      if (staffData.phoneNumber) {
        payload.phoneNumber = normalizePhoneNumber(staffData.phoneNumber);
      }

      let res;
      if (user.advisor) {
        res = await updateServiceAdvisor({
          params: {
            path: { advisorId: user.id },
          },
          body: {
            ...payload,
            phoneNumber: staffData.phoneNumber,
            businessPhoneNumber: staffData.extNumber,
            role: staffData.role,
          },
        });
      } else {
        res = await updateUser({
          params: {
            path: { userId: user.id },
          },
          body: payload,
        });
      }

      setUpdatingUser(false);
      if (res.error) {
        setError(res.error.message ?? "There was an error updating the user.");
        return;
      }
      refetch();
      onClose();
      toast.success("User updated successfully");
    }
  };

  const handleChangeStaffData = (e: any, column: string) => {
    const updatedValue = e.target.value;
    let phoneNumber = "";
    const newStaffData = { ...staffData };
    switch (column) {
      case "firstName":
        setValidFirstName(validateName(updatedValue));
        newStaffData[column] = updatedValue;
        break;
      case "lastName":
        setValidLastName(validateName(updatedValue));
        newStaffData[column] = updatedValue;
        break;
      case "email":
        setValidEmail(validateEmail(updatedValue));
        newStaffData[column] = updatedValue;
        break;
      case "phoneNumber": {
        if (updatedValue.length == 14) {
          phoneNumber = normalizePhoneNumber(updatedValue);
        }
        setValidPhoneNumber(validateFormattedPhoneNumber(updatedValue));
        newStaffData[column] = phoneNumber;
        break;
      }
      case "extNumber": {
        newStaffData[column] = updatedValue;
        break;
      }
      case "role":
        newStaffData[column] = updatedValue;
        break;
      default:
        null;
    }
    setStaffData(newStaffData);
  };

  async function handleAvatarChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (!event.target.files?.length) {
      console.error("No file was selected!");
      return;
    }

    setIsLoadingAvatar(true);
    const result = await uploadFile(event.target.files[0], "avatars/");
    if (result.error) {
      toast.error(`Error uploading avatar: ${result.error}`);
    } else {
      setStaffData({ ...staffData, avatarUrl: result.url });
    }
    setIsLoadingAvatar(false);
  }

  return (
    <Modal open={show} onClose={handleClose}>
      <Box sx={style}>
        <Stack
          display={"flex"}
          justifyContent={"space-between"}
          alignContent={"center"}
          mx={3}
          my={1}
          direction={"row"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography color="primary" fontSize={"large"} variant="h6">
              Edit User Information
            </Typography>
          </Box>
          <IconButton color={"primary"} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
        <Stack my={3} mx={5} display={"flex"} flexDirection={"column"} gap={3}>
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar
              alt={getDisplayName(staffData)}
              src={staffData.avatarUrl ?? undefined}
            />
            <LoadingButton
              color="primary"
              size="small"
              aria-label="upload avatar"
              onClick={() => avatarUploadRef.current?.click()}
              variant="outlined"
              loading={isLoadingAvatar}
            >
              Change
            </LoadingButton>
            <input
              ref={avatarUploadRef}
              type={"file"}
              hidden
              accept={"image/*"}
              onChange={handleAvatarChange}
            />
          </Box>
          <TextField
            required
            error={!validFirstName}
            helperText={!validFirstName ? "Enter a valid first name" : ""}
            color="secondary"
            value={staffData.firstName}
            label={"First Name"}
            onChange={(e: any) => handleChangeStaffData(e, "firstName")}
          />
          <TextField
            required
            error={!validLastName}
            helperText={!validLastName ? "Enter a valid last name" : ""}
            color="secondary"
            value={staffData.lastName}
            label={"Last Name"}
            onChange={(e: any) => handleChangeStaffData(e, "lastName")}
          />
          <TextField
            color="secondary"
            required
            error={staffData.email !== null ? !validEmail : false}
            helperText={
              staffData.email !== null
                ? !validEmail
                  ? "Not a valid email address"
                  : ""
                : ""
            }
            value={staffData.email}
            label={"Email"}
            onChange={(e: any) => handleChangeStaffData(e, "email")}
          />
          <PhoneNumberInput
            color="secondary"
            error={staffData.phoneNumber !== null ? !validPhoneNumber : false}
            helperText={
              staffData.phoneNumber !== null
                ? !validPhoneNumber
                  ? "Not a valid phone number"
                  : ""
                : ""
            }
            customInput={TextField}
            label="Phone Number"
            value={staffData.phoneNumber?.substring(2)}
            onChange={(e: any) => handleChangeStaffData(e, "phoneNumber")}
          />
          {user.advisor && (
            <>
              <TextField
                color="secondary"
                value={staffData.extNumber}
                label={"Ext Number"}
                onChange={(e) => handleChangeStaffData(e, "extNumber")}
              />
              <FormControl>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  labelId="demo-simple-select-labe"
                  value={staffData.role}
                  label={"Role"}
                  onChange={(e) => handleChangeStaffData(e, "role")}
                >
                  <MenuItem value={"ADVISOR"}>Advisor</MenuItem>
                  <MenuItem value={"EMPLOYEE"}>Employee</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
          <DisplayError error={error} />
        </Stack>
        <Stack
          mb={3}
          display={"flex"}
          spacing={2}
          justifyContent={"center"}
          width={"100%"}
          direction={"row"}
        >
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={
              !validEmail ||
              !validFirstName ||
              !validLastName ||
              !validPhoneNumber ||
              isLoadingAvatar ||
              staffData.firstName === "" ||
              staffData.lastName === ""
            }
            loading={updatingUser}
            loadingPosition="end"
            endIcon={<ManageAccountsIcon />}
            variant="contained"
            onClick={clickUpdateUser}
          >
            Update
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}
