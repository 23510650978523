import { SendShopEstimateInput } from "../src/requests/request.dto";
import Request, { LatLng, Service } from "../src/requests/request.entity";
import { Document, Image } from "./request.types";
import { RequestState } from "./states";

export enum RequestHistoryEvent {
  StatusChange = "statusChange",
  FollowUp = "followUp",
  PartsOrdered = "partsOrdered",
  PartsReceived = "partsReceived",
  WorkCompleted = "workCompleted",
  ShopInvoice = "shopInvoice",
  Diagnostics = "diagnostics",
  NewSupplement = "newSupplement",
  AcceptNewSupplement = "acceptNewSupplement",
  RejectNewSupplement = "rejectNewSupplement",
  UploadFiles = "uploadFiles",
  ReschedulePickup = "reschedulePickup",
  RescheduleDropoff = "rescheduleDropoff",
  UpdateShopECD = "updateShopECD",
  UpdateCustomerECD = "updateCustomerECD",
  PaidShop = "paidShop",
  ShopEstimate = "shopEstimate",
  CancelPickup = "cancelPickup",
  CancelRequest = "cancelRequest",
  OverridePaidAt = "overridePaidAt",
  AcceptShopAssignment = "acceptShopAssignment",
  UpdateMileage = "updateMileage",
  UpdateVehicle = "updateVehicle",
  UploadShopWorkOrder = "uploadShopWorkOrder",
}

export enum RequestHistoryNote {
  EstimateSkipped = "Estimate skipped",
  EstimateAutoApproved = "Estimate auto approved",
}

export type RequestHistoryRow = { createdAt?: Date | null } & (
  | PartsOrderedRequestHistoryEvent
  | PartsReceivedRequestHistoryEvent
  | WorkCompletedRequestHistoryEvent
  | FollowUpRequestHistoryEvent
  | ShopInvoiceHistoryEvent
  | DiagnosticsRequestHistoryEvent
  | NewSupplementRequestHistoryEvent
  | AcceptNewSupplementRequestHistoryEvent
  | RejectNewSupplementRequestHistoryEvent
  | UploadFilesRequestHistoryEvent
  | ReschedulePickupRequestHistoryEvent
  | SchedulePickupRequestHistoryEvent
  | RescheduleDropoffRequestHistoryEvent
  | ScheduleDropoffRequestHistoryEvent
  | UpdateShopECDRequestHistoryEvent
  | UpdateCustomerECDRequestHistoryEvent
  | InShopRequestHistoryEvent
  | ApproveEstimateRequestHistoryEvent
  | DeclineEstimateRequestHistoryEvent
  | RejectShopRequestHistoryEvent
  | AwaitingPaymentRequestHistoryEvent
  | PaidShopRequestHistoryEvent
  | NewRequestHistoryEvent
  | AssignShopRequestHistoryEvent
  | ApplyPromoCodeRequestHistoryEvent
  | AcceptShopAssignmentRequestHistoryEvent
  | ShareEstimateRequestHistoryEvent
  | CancelRequestHistoryEvent
  | ShopEstimateRequestHistoryEvent
  | CancelPickupHistoryEvent
  | OverrideStatusRequestHistoryEvent
  | OverridePaidAtRequestHistoryEvent
  | UpdateMileageRequestHistoryEvent
  | UpdateVehicleRequestHistoryEvent
  | ShopWorkOrderRequestHistoryEvent
);

export type PartsOrderedChangeset = {
  images: Image[];
  documents: Document[];
  note: string | null;
  shopECD: string | null;
  channelId: number;
};
export interface PartsOrderedRequestHistoryEvent {
  eventType: RequestHistoryEvent.PartsOrdered;
  changeset: PartsOrderedChangeset;
}

export type PartsReceivedChangeset = {
  images: Image[];
  note: string | null;
  shopECD: string | null;
  channelId: number;
};

export interface PartsReceivedRequestHistoryEvent {
  eventType: RequestHistoryEvent.PartsReceived;
  changeset: PartsReceivedChangeset;
}

export type WorkCompletedChangeset = {
  mileageOut: number;
  images: Image[];
  note?: string | null;
  invoiceAmount?: number;
  channelId: number;
};

export interface WorkCompletedRequestHistoryEvent {
  eventType: RequestHistoryEvent.WorkCompleted;
  changeset: WorkCompletedChangeset;
}

export type PaidShopChangeset = {
  note: string | null;
};

export interface PaidShopRequestHistoryEvent {
  eventType: RequestHistoryEvent.PaidShop;
  changeset: PaidShopChangeset;
}

export type ShopInvoiceChangeset = {
  invoice: Document;
  partsReceipts?: Document[];
};

interface ShopInvoiceHistoryEvent {
  eventType: RequestHistoryEvent.ShopInvoice;
  changeset: ShopInvoiceChangeset;
}

export type FollowUpChangeSet = {
  followUp: number;
};

interface FollowUpRequestHistoryEvent {
  eventType: RequestHistoryEvent.FollowUp;
  changeset: FollowUpChangeSet;
}

export type DiagnosticsChangeset = {
  additionalWorkRecommended: boolean;
  mileageIn: number;
  shopECD: string;
  images: { imageUrl: string }[];
  note?: string | null;
  channelId: number;
  status?: RequestState;
};

export interface DiagnosticsRequestHistoryEvent {
  eventType: RequestHistoryEvent.Diagnostics;
  changeset: DiagnosticsChangeset;
}

export type NewSupplementChangeset = {
  shopECD: string;
  documents: Document[];
  images: { imageUrl: string }[];
  note?: string | null;
  channelId: number;
};

export interface NewSupplementRequestHistoryEvent {
  eventType: RequestHistoryEvent.NewSupplement;
  changeset: NewSupplementChangeset;
}

export type ShopEstimateChangeset = Omit<SendShopEstimateInput, "note"> & {
  status: RequestState;
};

export interface ShopEstimateRequestHistoryEvent {
  eventType: RequestHistoryEvent.ShopEstimate;
  changeset: ShopEstimateChangeset;
}

export type AcceptOrRejectNewSupplementChangeset = {
  accepted: boolean;
  status: RequestState;
};

interface AcceptNewSupplementRequestHistoryEvent {
  eventType: RequestHistoryEvent.AcceptNewSupplement;
  changeset: AcceptOrRejectNewSupplementChangeset;
}

interface RejectNewSupplementRequestHistoryEvent {
  eventType: RequestHistoryEvent.RejectNewSupplement;
  changeset: AcceptOrRejectNewSupplementChangeset;
}

export type UploadFilesChangeset = {
  images: Image[];
  documents: Document[];
  channelId: number;
};

export interface UploadFilesRequestHistoryEvent {
  id?: number;
  eventType: RequestHistoryEvent.UploadFiles;
  changeset: UploadFilesChangeset;
}

export type ReschedulePickupChangeset = {
  pickupAddress: string;
  pickupLatLon: LatLng;
  startPickupDate: Date;
  endPickupDate: Date;
  zip: string | null;
};

export interface CancelPickupChangeset {
  pickupAddress: null;
  pickupLatLon: null;
  startPickupDate: null;
  endPickupDate: null;
  status: RequestState;
}

export interface ReschedulePickupRequestHistoryEvent {
  eventType: RequestHistoryEvent.ReschedulePickup;
  changeset: ReschedulePickupChangeset;
}

export interface SchedulePickupChangeset extends ReschedulePickupChangeset {
  status: RequestState;
}

export interface SchedulePickupRequestHistoryEvent {
  eventType: RequestHistoryEvent.StatusChange;
  changeset: SchedulePickupChangeset;
}

export type RescheduleDropoffChangeset = {
  dropoffAddress: string;
  dropoffLatLon: LatLng;
  startDropoffDate: Date;
  endDropoffDate: Date;
  zip: string | null;
};

export interface RescheduleDropoffRequestHistoryEvent {
  eventType: RequestHistoryEvent.RescheduleDropoff;
  changeset: RescheduleDropoffChangeset;
}

export interface CancelPickupHistoryEvent {
  eventType: RequestHistoryEvent.CancelPickup;
  changeset: CancelPickupChangeset;
}

export interface ScheduleDropoffChangeset extends RescheduleDropoffChangeset {
  status: RequestState;
}

export interface ScheduleDropoffRequestHistoryEvent {
  eventType: RequestHistoryEvent.StatusChange;
  changeset: ScheduleDropoffChangeset;
}

type UpdateShopECDChangeset = {
  shopECD: string | null;
};

export interface UpdateShopECDRequestHistoryEvent {
  eventType: RequestHistoryEvent.UpdateShopECD;
  changeset: UpdateShopECDChangeset;
}

type UpdateCustomerECDChangeset = {
  customerECD: string | null;
};

export interface UpdateCustomerECDRequestHistoryEvent {
  eventType: RequestHistoryEvent.UpdateCustomerECD;
  changeset: UpdateCustomerECDChangeset;
}

type UpdateVehicleChangeset = {
  vehicleId: number | null;
};

export interface UpdateVehicleRequestHistoryEvent {
  eventType: RequestHistoryEvent.UpdateVehicle;
  changeset: UpdateVehicleChangeset;
}

export interface InShopChangeset {
  images?: { imageUrl: string }[];
  note?: string | null;
  shopECD: string | null;
  status: RequestState.IN_SHOP;
  channelId: number;
}

export type InShopRequestHistoryEvent = {
  changeset: InShopChangeset;
  eventType: RequestHistoryEvent.StatusChange;
};

export interface ApproveEstimateChangeset {
  status: RequestState;
  services: Service[];
  estimateAmount: number;
}

export type ApproveEstimateRequestHistoryEvent = {
  changeset: ApproveEstimateChangeset;
  eventType: RequestHistoryEvent.StatusChange;
};

export interface DeclineEstimateChangeset {
  status: RequestState;
  services: Service[];
  isEstimateDeclined?: boolean;
}

export type DeclineEstimateRequestHistoryEvent = {
  changeset: DeclineEstimateChangeset;
  eventType: RequestHistoryEvent.StatusChange;
};

export interface RejectShopChangeset {
  status: RequestState;
  shopId: null;
  rejected: true;
}

export type RejectShopRequestHistoryEvent = {
  changeset: RejectShopChangeset;
  eventType: RequestHistoryEvent.StatusChange;
};

export interface AwaitingPaymentChangeset {
  status: RequestState.AWAITING_PAYMENT;
  invoiceAmount: number;
}

export type AwaitingPaymentRequestHistoryEvent = {
  changeset: AwaitingPaymentChangeset;
  eventType: RequestHistoryEvent.StatusChange;
};

export type NewRequestHistoryEvent = {
  changeset: Partial<Request>;
  eventType: RequestHistoryEvent.StatusChange;
};

export interface AssignShopChangeset {
  rejected: false;
  shopId: number | null;
  taxRate: number;
  taxRateLaborCollisionRepair: number;
  taxRatePaintSupplyMaterial: number;
  status?: RequestState;
  pickupAddress?: null;
  pickupLatLon?: null;
  startPickupDate?: null;
  endPickupDate?: null;
}

export type AssignShopRequestHistoryEvent = {
  changeset: AssignShopChangeset;
  eventType: RequestHistoryEvent.StatusChange;
};

export interface ApplyPromoCodeChangeset {
  discountCode: string;
}

export type ApplyPromoCodeRequestHistoryEvent = {
  changeset: ApplyPromoCodeChangeset;
  eventType: RequestHistoryEvent.StatusChange;
};

export interface AcceptShopAssignmentChangeset {
  status: RequestState;
  shopId: number;
}

export type AcceptShopAssignmentRequestHistoryEvent = {
  changeset: AcceptShopAssignmentChangeset;
  eventType: RequestHistoryEvent.AcceptShopAssignment;
};

export interface ShareEstimateChangeset {
  status?: RequestState;
  totalPrice: number;
}

export type ShareEstimateRequestHistoryEvent = {
  changeset: ShareEstimateChangeset;
  eventType: RequestHistoryEvent.StatusChange;
};

export interface CancelRequestChangeset {
  status: RequestState.IN_SHOP | RequestState.LOST;
  closedLostUserId: number;
  closedLostReason: string;
  closedLostReasonComments: string | null;
}

export type CancelRequestHistoryEvent = {
  changeset: CancelRequestChangeset;
  eventType: RequestHistoryEvent.CancelRequest;
};

export type OverrideStatusRequestHistoryEvent = {
  eventType: RequestHistoryEvent.StatusChange;
  changeset: { status: RequestState; override: true };
};

export type OverridePaidAtRequestHistoryEvent = {
  eventType: RequestHistoryEvent.OverridePaidAt;
  changeset: { override: true; paidAt: Date | null };
};

export type UpdateMileageRequestHistoryEvent = {
  eventType: RequestHistoryEvent.UpdateMileage;
  changeset: { mileageIn?: number; mileageOut?: number };
};

export type ShopWorkOrderChangeset = {
  shopWorkOrderPdfUrl: string | null;
};

export type ShopWorkOrderRequestHistoryEvent = {
  eventType: RequestHistoryEvent.UploadShopWorkOrder;
  changeset: ShopWorkOrderChangeset;
};
