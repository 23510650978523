import React from "react";

import "../styles/globals.css";
import { ApolloProvider } from "@apollo/client";
import { NoSsr } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { NextPage } from "next";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import { Toaster } from "react-hot-toast";

import { ColumnLayout } from "shared/layouts/ColumnLayout";
import { ColorModeProvider } from "shared/providers/ColorModeProvider";
import { UnreadRequestsProvider } from "shared/providers/UnreadRequestsProvider";

import PageHead from "../components/PageHead";
import SideBar from "../components/SideBar";
import client from "../lib/apollo-client";
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import { AccountSettingsProvider } from "../providers/AccountSettings/AccountSettingsProvider";
import { AuthProvider } from "../providers/AuthProvider";

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY as string);
const routesRequireLogo = ["/auth/signin", "/auth/invite"];

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const disableAuth =
    router.asPath.startsWith("/auth/") ||
    router.asPath.startsWith("/accept") ||
    router.asPath.startsWith("/approve") ||
    router.asPath.startsWith("/verify") ||
    router.asPath.startsWith("/pay") ||
    router.asPath.startsWith("/terms_and_conditions");

  const inRequestPage = router.asPath.startsWith(
    `/requests/${router.query.requestId}`
  );

  const showColumnLayoutWithLogo = routesRequireLogo.some((route) =>
    router.asPath.includes(route)
  );

  return (
    <NoSsr>
      <PageHead />
      <AuthProvider disableAuth={disableAuth}>
        <ColorModeProvider forceAllowDarkMode>
          <AccountSettingsProvider>
            <ApolloProvider client={client}>
              <Elements stripe={stripePromise}>
                <SideBar disabled={disableAuth} noPadding={inRequestPage}>
                  <Toaster
                    position="top-right"
                    toastOptions={{
                      duration: 10000,
                      style: {
                        fontWeight: 700,
                        maxWidth: "750px",
                      },
                    }}
                  />
                  <UnreadRequestsProvider>
                    {showColumnLayoutWithLogo ? (
                      <ColumnLayout showLogo={showColumnLayoutWithLogo}>
                        <Component {...pageProps} />
                      </ColumnLayout>
                    ) : (
                      <Component {...pageProps} />
                    )}
                  </UnreadRequestsProvider>
                </SideBar>
              </Elements>
            </ApolloProvider>
          </AccountSettingsProvider>
        </ColorModeProvider>
      </AuthProvider>
      <Analytics />
      <SpeedInsights />
    </NoSsr>
  );
}

export default withLDProvider<AppProps & JSX.IntrinsicAttributes>({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY as string,
  options: {
    streaming: true,
  },
})(MyApp);
